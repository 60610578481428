<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Credit customer account</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="creditAccount()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="8"
                  class="mb-2"
                >
                  <v-autocomplete
                    v-model="form.user_id"
                    :items="users"
                    :loading="isUsersLoading"
                    :search-input.sync="users_search"
                    :filter="filterObject"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="id"
                    cache-items
                    hide-no-data
                    label="Customer name"
                    placeholder="Search customer name or account number"
                    :rules="[
                      v => !!v || 'Customer name is required',
                    ]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for customer
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      {{ data.item.name }} - {{ data.item.account_number }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar class="primary font-weight-light white--text">
                        {{ data.item.name.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.account_number">
                        </v-list-item-subtitle>
                        <br />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <small
                    v-show="form.errors.has('user_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('user_id')
                  }}</small>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="form.amount"
                    label="Amount"
                    placeholder="1000"
                    :rules="[
                      v => !!v || 'required', v => v > 0 || 'The value must be greater than 0'
                    ]"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('amount')"
                    class="validation-error"
                  >{{
                    form.errors.get('amount')
                  }}</small>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="form.account_type"
                    :items="account_types"
                    item-text="name"
                    item-value="id"
                    label="Account type"
                  ></v-select>
                </v-col>
                <v-col
                  cols="8"
                >
                  <v-text-field
                    v-model="form.mpesa_transaction_reference"
                    label="Mpesa transaction reference (Optional)"
                    placeholder="QDC99NCOLX"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('mpesa_transaction_reference')"
                    class="validation-error"
                  >{{
                    form.errors.get('mpesa_transaction_reference')
                  }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
  },
  data: () => ({
    form: new Form({
      user_id: '',
      amount: '',
      account_type: 1,
    }),
    account_types: [
      {
        id: 1,
        name: 'Main',
      },
      {
        id: 2,
        name: 'Connection Fee',
      },
    ],
    users: [],
    isUsersLoading: false,
    isStationsLoading: true,
    isTypesLoading: true,
    action: 'Credit',
    users_search: null,
    month_menu: false,
    bill_due_at_menu: false,
  }),
  computed: {
    meters_url() {
      return 'meters'
    },
    showAutomaticMeterFields() {
      return this.form.mode === 1
    },
  },
  watch: {
    users_search(value) {
      this.getUsers(value)
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getUsers()
      }
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
                || item.account_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.account_type = 1
    },
    getUsers: _.debounce(function (value) {
      this.isUsersLoading = true
      axios
        .get(`users?searchByNameAndAccountNo=${value}`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    creditAccount() {
      this.form
        .post('users-account/credit')
        .then(() => {
          this.$notification.success('Account credited successfully, transaction will be processed shortly')
          this.$emit('close')
          this.$emit('account-credited')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
    min-height: 48px !important;
    height: unset !important;
}
</style>
